export function getColor(title: string, author: string, opacity: number=0.7) {
  // Combine title and author into a single string
  const inputString = `${title || ''}${author || ''}`;

  // Create a hash from the input string
  let hash = 0;
  for (let i = 0; i < inputString.length; i++) {
    // Simple hash function (djb2)
    hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
    hash &= hash; // Convert to 32bit integer
  }

  // Generate RGB values from the hash
  let r = (hash >> 16) & 0xff;
  let g = (hash >> 8) & 0xff;
  let b = hash & 0xff;

  // Adjust RGB values to be dimmed (not too bright)
  const dimFactor = 0.7; // Adjust between 0 (dark) and 1 (bright)
  r = Math.floor(r * dimFactor);
  g = Math.floor(g * dimFactor);
  b = Math.floor(b * dimFactor);

  // Ensure opacity is within 0 to 1
  opacity = Math.max(0, Math.min(opacity, 1));

  // Return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
