import React from "react";

// This function will take a string and return a React element highlighting quoted substrings
export const renderMaybeSearchTerm = (str: string) => {
  // Regex to match all quoted substrings in the format "text"
  const quotePattern = /"(.*?)"/g; // Global flag to match multiple quotes

  // Split the string into parts using the regex pattern
  const parts = str.split(quotePattern);

  // Create an array to hold the final result
  const result = [];

  // Iterate through the parts and wrap quoted text in a <span> element
  for (let i = 0; i < parts.length; i++) {
    if (i % 2 === 0) {
      // Non-quoted text (even indices)
      result.push(<span key={`text-${i}`}>{parts[i]}</span>);
    } else {
      // Quoted text (odd indices)
      result.push(
        <span key={`quote-${i}`} className="BookSearch__SearchTerm">
          &quot;{parts[i]}&quot;
        </span>
      );
    }
  }

  // Return the combined elements
  return <>{result}</>;
};
