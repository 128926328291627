import React, {
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import { AddLinkProps, DEFAULT_PRIORITY, LinksDisplayRegion, NamespacedLinksDictionary, PreviousRelacements, QuickLink, QuickLinksContext, RemoveLinkProps, ReplaceLinkProps, UnfilledAction, UnfilledRequest } from './QuickLinksContext';
import { l } from '../../utils/log';

const log = l("QuickLinksProvider");

// Initialize the dictionary with an empty array for each namespace
const initializeNamespacedLinksDictionary = (): NamespacedLinksDictionary => {
  log("initializeNamespacedLinksDictionary");
  const initialState: NamespacedLinksDictionary = {} as NamespacedLinksDictionary;
  Object.values(LinksDisplayRegion).forEach((namespace) => {
    initialState[namespace] = [];
  });
  return initialState;
};

// Helper function to insert a link into the sorted array
const insertLinkSorted = (links: QuickLink[], newLink: QuickLink): QuickLink[] => {
  const index = links.findIndex((link) => link.priority > newLink.priority);
  if (index === -1) {
    return [...links, newLink]; // Add at the end if no link has a lower priority
  }
  return [
    ...links.slice(0, index),
    newLink,
    ...links.slice(index),
  ]; // Insert the new link at the correct position
};

export const QuickLinksProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState({
    namespacedLinksDictionary: initializeNamespacedLinksDictionary(),
    unfilledRequests: [] as UnfilledRequest[],
    previousReplacements: {} as PreviousRelacements,
  });

  // Add link logic
  const addLink = useCallback(({ link, namespace = LinksDisplayRegion.common, priority = 0 }: AddLinkProps) => {
    setState((prevState) => {
      const { namespacedLinksDictionary, unfilledRequests, previousReplacements } = prevState;
      const idx = unfilledRequests.findIndex((ur) => ur.id === link.id);
      if (idx !== -1) {
        const unfilledRequest = unfilledRequests[idx];
        if (unfilledRequest.actionPriority > priority) {
          return prevState; // skip adding if marked for removal by another call with higher priority
        }
      }
      return {
        namespacedLinksDictionary: {
          ...namespacedLinksDictionary,
          [namespace]: insertLinkSorted(namespacedLinksDictionary[namespace] || [], link.actionPriority !== undefined ? link : { ...link, actionPriority: DEFAULT_PRIORITY }), // Update the specific namespace's links
        },
        unfilledRequests: idx !== -1 ? unfilledRequests.splice(idx, 1): unfilledRequests,
        previousReplacements,
      };
    });
  }, []);

  // Replace link logic, reuse add logic if link is not found
  const replaceLink = useCallback(({ newLink, oldLinkId, namespace = LinksDisplayRegion.common }: ReplaceLinkProps) => {
    setState((prevState) => {
      const { namespacedLinksDictionary, unfilledRequests, previousReplacements } = prevState;
      log("replaceLink: prevState", prevState.namespacedLinksDictionary);
      log("replaceLink: newLink", newLink);
      log("replaceLink: oldLinkId", oldLinkId);
      log("replaceLink: namespace", namespace);

      log("replaceLink: prevLinks", namespacedLinksDictionary);
      const namespaceLinks = namespacedLinksDictionary[namespace] || [];
      const index = namespaceLinks.findIndex((link) => link.id === oldLinkId);

      // When replacing an id (say id A), we also want to replace the id's that A was replaced by, so that the spot is clean
      // TODO this is not going to work with concurrency: if we queue unfulfulled replacements, then it gets messed up because
      // a real attempt at placing a link that was marked in the previousReplacementsForOldId will fail.
      // To circumvent this, we dont queue unfulfilled for previousReplacementsForOldId, but this means that if a previousReplacementsForOldId
      // adds itself after this call, then it will be added resluting in two.
      // SOLUTION: ?
      const previousReplacementsForOldId = previousReplacements[oldLinkId] || []
      const nsLinksWithoutPreviousReplacementsForOldId = namespaceLinks.filter(link => previousReplacementsForOldId.indexOf(link.id) !== -1);

      log("replaceLink: previousReplacementsForOldId", previousReplacementsForOldId);

      const nextPreviousReplacementsForOldId = (previousReplacementsForOldId.indexOf(newLink.id) === -1) ? [...previousReplacementsForOldId, newLink.id] : previousReplacementsForOldId;
      const nextPreviousReplacements = {
        ...previousReplacements,
        [oldLinkId]: nextPreviousReplacementsForOldId
      };

      log("index", index);
      if (index === -1) {
        // If no link with the given id is found, use add logic
        log("replaceLink: Warning: Trying to replace unexistent namespace quick link");
        log("replaceLink: Warning: ...Existing: ", Object.keys(namespacedLinksDictionary));
        log("replaceLink: Warning: ...Arg Namespace: ", namespace);
        log("replaceLink: Warning: Suggestion, make sure to call removeLink with the correct namespace param");
        // setUnfilledRequests((prevRequests) => )
        const isThereAnUnfilledRequestForRemoval = -1 !== unfilledRequests.findIndex(r => oldLinkId === r.id);
        return {
          namespacedLinksDictionary: {
            ...namespacedLinksDictionary,
            [namespace]: insertLinkSorted(nsLinksWithoutPreviousReplacementsForOldId, newLink),
          },
          unfilledRequests: isThereAnUnfilledRequestForRemoval
            ? unfilledRequests
            : [
                ...unfilledRequests,
                {
                  id: oldLinkId,
                  namespace,
                  actionPriority: newLink.actionPriority || DEFAULT_PRIORITY,
                  action: UnfilledAction.removeLink
                },
            ],
          previousReplacements: nextPreviousReplacements,
        };
      }

      //lReplace the existing link at the found index with the new link
      const updatedLinks = [
        ...nsLinksWithoutPreviousReplacementsForOldId.slice(0, index),
        newLink, // Replace with the new link
        ...nsLinksWithoutPreviousReplacementsForOldId.slice(index + 1),
      ];

      log("replaceLink: updatedLinks", index);
      return {
        namespacedLinksDictionary: {
          ...namespacedLinksDictionary,
          [namespace]: updatedLinks, // Update the specific namespace's links
        },
        unfilledRequests,
        previousReplacements: nextPreviousReplacements,
      };
    });
  }, []);

  // Remove link logic
  const removeLink = useCallback(({ id, actionPriority = DEFAULT_PRIORITY, namespace = LinksDisplayRegion.common }: RemoveLinkProps) => {
    setState((prevState) => {
      log("removeLink: prevState", prevState)
      log("removeLink: CAALLL to REMOVE", id, actionPriority, namespace);
      const { namespacedLinksDictionary, unfilledRequests, previousReplacements } = prevState;
      if (!namespacedLinksDictionary[namespace] || namespacedLinksDictionary[namespace].length <= 0) {
        log("removeLink: Warning: Trying to remove unexistent namespace quick link");
        log("removeLink: Warning: ...Existing: ", Object.keys(namespacedLinksDictionary));
        log("removeLink: Warning: ...Arg Namespace: ", namespace);
        log("removeLink: Warning: Suggestion, make sure to call removeLink with the correct namespace param");
        return {
          namespacedLinksDictionary,
          unfilledRequests: [...unfilledRequests, { id, namespace, actionPriority, action: UnfilledAction.removeLink}],
          previousReplacements,
        };
      }
      return {
        namespacedLinksDictionary: {
          ...namespacedLinksDictionary,
          [namespace]: (namespacedLinksDictionary[namespace] || []).filter((link) => link.id !== id),
        },
        unfilledRequests,
        previousReplacements,
      };
    });
  }, []);

  return (
    <QuickLinksContext.Provider value={{ addLink, replaceLink, removeLink, state }}>
      {children}
    </QuickLinksContext.Provider>
  );
};

export const useQuickLinks = (namespace: LinksDisplayRegion = LinksDisplayRegion.common) => {
  const context = useContext(QuickLinksContext);
  if (!context) {
    throw new Error('useQuickLinks must be used within a QuickLinksProvider');
  }
  const { state: { namespacedLinksDictionary }, addLink, removeLink, replaceLink } = context;

  // Return links for the given namespace, defaulting to an empty array if none exist
  const namespaceLinks = namespacedLinksDictionary[namespace] || [];

  return { links: namespaceLinks, addLink, removeLink, replaceLink };
};
