import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Login from "../Login";
import { getSearchRouteFromParams } from "../../utils/search";
import "./index.scss";
import SearchBar from "../SearchBar";
import { LinkableElementId, LinksDisplayRegion } from "../../contexts/QuickLinks/QuickLinksContext";
import { useReplaceQuickLink } from "../../hooks/useReplaceQuickLink";
import { useTranslation } from "react-i18next";
import AppVersion from "../AppVersion";
import { useSearchQuery } from "../../contexts/SearchQuery/SearchQueryProvider";

type ComponentProps = {
  modifier?: "centered-logo" | "left-on-mobile";
}

const containerLink = {
  id: LinkableElementId.navBar,
  href: `#${LinkableElementId.navBar}`,
  label: 'Go to navigation',
  priority: 20,
  actionPriority: 1,
};

const replaceInstruction = {
  oldLinkId: LinkableElementId.pageHeader,
  newLink: containerLink,
  namespace: LinksDisplayRegion.pageComponent,
};

const NavBar: React.FC<ComponentProps> = function ({ modifier = "left-on-mobile"}) {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const { searchQuery, setSearchQuery, alreadySearched } = useSearchQuery();

  useReplaceQuickLink(replaceInstruction);

  return (
    <div
      className="Navbar__Container"
      id={LinkableElementId.navBar}
      aria-label={t("Sitewide utilities")}
    >
      <nav
        className={`Navbar__NavContainer Navbar__NavContainer--${modifier}`}
        aria-label={t("Main Navigation")}
      >
        <Link
          className="Navbar__LogoLink clickable"
          aria-label={t("Home")}
          onClick={event => {
            event.preventDefault();
            const { youth, format } = searchQuery;
            setShowAdvancedSearch(false);
            navigate(getSearchRouteFromParams({
              page: 0,
              youth,
              format
            }));
          } }
          to={"#"}
        >
          <img
            aria-hidden={true}
            className="Navbar__Logo"
            src={`/${import.meta.env.VITE_ORIGIN}-Logo.png`}
            alt={`Logo ${import.meta.env.VITE_ORIGIN_FULL}`}
          />
        </Link>
      </nav>
      <SearchBar
        id="search-bar"
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        setShowAdvancedSearch={setShowAdvancedSearch}
        showAdvancedSearch={showAdvancedSearch}
        alreadySearched={alreadySearched}
      />
      <aside className="Navbar__AsideContainer" role="complementary">
        <AppVersion />
        <Login />
      </aside>
    </div>
  );
};

export default NavBar;
