import React from "react";
import { Book } from "../../types/book";
import getBookCover, {
  GENERIC_COVER_IMAGE_URL,
  REMOTE_GENERIC_COVER_IMAGE_URL,
} from "../../utils/cover";
import "./index.scss";
import { l } from "../../utils/log";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next/typescript/t";
import { getColor } from "../../utils/getColor";

type BookCoverProps = {
  cover: Book["cover"];
  title: Book["title"];
  author: Book["author"];
  modifier?: "bookInfo"
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const log = l("BookCoverImage");

const getDisplayValue = (
  value: string | undefined | null,
  t: TFunction<[string], undefined>
) => (value && value.trim() ? value : t("Unknown"));

export default function BookCoverImage({
  cover,
  title,
  author,
  modifier,
  ...rest
}: BookCoverProps) {
  log(cover);
  log(GENERIC_COVER_IMAGE_URL);
  const { t } = useTranslation(["common"]);

  if (
    GENERIC_COVER_IMAGE_URL === cover ||
    REMOTE_GENERIC_COVER_IMAGE_URL === cover
  ) {
    return (
      <div
        className={`BookCoverImage${modifier ? ` BookCoverImage--${modifier}`: ""}`}
      >
        <img
          src={REMOTE_GENERIC_COVER_IMAGE_URL}
          alt={t("Book Cover")}
        />
        <svg
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="100%" height="100%" fill={`${getColor(title, author, 0.4)}`} />
          <foreignObject x="0" y="0" width="100%" height="100%" className="ForeignObject">
            <div className="BookCoverImage__OverlayContent">
              <div className={`BookCoverImage__TextContainer${modifier ? ` BookCoverImage__TextContainer--${modifier}`: ""}`}>
                <p className="BookCoverImage__Title">{getDisplayValue(title, t)}</p>
                <p className="BookCoverImage__Author">{getDisplayValue(author, t)}</p>
              </div>
            </div>
          </foreignObject>
        </svg>
      </div>
    );
  } else {
    return (
      <img
        src={getBookCover(cover)}
        alt={t("Book Cover")}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = getBookCover(cover);
        }}
        {...rest}
      />
    );
  }
}
