import React from "react";
import "./index.scss";
import { TabIndex } from "../../types/accessibility";

type SortingButtonProps = {
  label: string;
  active: boolean;
  asc: boolean;
  disabled: boolean;
  onClick: () => void;
  ariaLabel: string;
  tabIndex?: TabIndex;
};

const SortingButton: React.FC<SortingButtonProps> = ({
  label,
  active,
  asc,
  disabled,
  onClick,
  ariaLabel,
  tabIndex=TabIndex.notReachableButProgramaticallyFocusable,
}) => {
  return (
    <button
      className={`SortingButton ${
        active ? "SortingButton--active" : ""
      }`}
      onClick={onClick}
      aria-disabled={disabled}
      aria-pressed={active}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      {
        ...(tabIndex !== TabIndex.reachablePriorityLowest
        ? { "data-toolbar-item": "true" }
        :  {})
      }
    >
      {label} {asc ? "▲" : "▼"}
    </button>
  );
};

export default SortingButton;
