import { createContext } from "react";
import { NewSearchProps } from "../../types/search";

export interface SearchQueryContextType {
  searchQuery: NewSearchProps;
  setSearchQuery: React.Dispatch<React.SetStateAction<NewSearchProps>>;
  alreadySearched: boolean;
  alreadySearchedSearchQuery: NewSearchProps;
}

export const SearchQueryContext = createContext<SearchQueryContextType | undefined>(undefined);