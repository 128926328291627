import React, { HtmlHTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { getSearchRouteFromParams, toIntOr } from "../../utils/search";
import { ValuesParam } from "../../types/search";
import "./index.scss";
import { TabIndex } from "../../types/accessibility";
import { l } from "../../utils/log";

const log = l("Pagination");

const countPerPage = toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10);
type ComponentProps = {
  totalCount: number;
  receivedCount: number;
  newSearchProps: ValuesParam;
  withinToolbar?: boolean;
} & HtmlHTMLAttributes<HTMLDivElement>;

const Pagination: React.FC<ComponentProps> = ({ totalCount, receivedCount, newSearchProps, withinToolbar = false, ...rest }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const { page } = newSearchProps;
  log("Page", page);
  const humanReadablePage = page + 1;
  const displayedPreviouslyCount = page * (countPerPage || 0);
  const displayedSoFarCount = displayedPreviouslyCount + (receivedCount || 0);
  const totalNumberOfPages = Math.ceil(totalCount / countPerPage);

  const handlePageChange = (direction: "forward" | "backward") => {
    if (direction === "backward" && page === 0) return;

    const maxPageNumber = totalCount / countPerPage - 1;

    if (direction === "forward" && page < maxPageNumber) {
      navigate(getSearchRouteFromParams({
        ...newSearchProps,
        page: page + 1,
      }));
    } else if (direction === "backward" && page > 0) {
      navigate(getSearchRouteFromParams({
        ...newSearchProps,
        page: page - 1,
      }));
    }
  };

  return (
    <div className="Pagination__Container" {...rest} role="group">
      <a
        className={"Pagination__ChangePage"}
        aria-disabled={page === 0}
        {
          ...(
          withinToolbar
            ? {
              tabIndex: TabIndex.notReachableButProgramaticallyFocusable,
              "data-toolbar-item": "true"
            }
            : {}
          )
        }
        onClick={() => {
          handlePageChange("backward");
        }}
        aria-label="Page précédente"
      >
        <MdOutlineNavigateBefore aria-hidden={true} size={25} />
      </a>
      <div
        className="Pagination__Info"
        aria-label={`${t("Page")} ${humanReadablePage} ${t("of")} ${totalNumberOfPages}`}
      >
        <p aria-hidden="true">
          {t("Page")}&nbsp;<span className="Pagination__Info--page-current">{humanReadablePage}</span>&nbsp;{t("of")}&nbsp;<span className="Pagination__Info--page">{totalNumberOfPages}</span>
        </p>
      </div>
      <a
        className={"Pagination__ChangePage"}
        aria-disabled={displayedSoFarCount >= totalCount}
        {
          ...(
          withinToolbar
            ? {
              tabIndex: TabIndex.notReachableButProgramaticallyFocusable,
              "data-toolbar-item": "true"
            }
            : {}
          )
        }
        onClick={() => {
          handlePageChange("forward");
        }}
        aria-label="Page suivante"
      >
        <MdOutlineNavigateNext aria-hidden={true} size={25} />
      </a>
    </div>
  );
};

export default Pagination;
