// SearchQueryContext.tsx
import React, { useContext, useEffect, useMemo, useState } from "react";
import { NewSearchProps } from "../../types/search";
import { SearchQueryContext } from "./SearchQueryContext";
import SearchContext from "../Search/SearchContext";
import { toIntOr } from "../../utils/search";
import { l } from "../../utils/log";

const log = l("SearchQueryProvider");

const SearchQueryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const alreadySearchedSearchQuery = useContext(SearchContext);
  const { queryText, queryType, gatewayGenreCode, page, format, youth, sortCriteria } = alreadySearchedSearchQuery;

  const [searchQuery, setSearchQuery] = useState<NewSearchProps>({
    queryText: queryText || "",
    queryType,
    page,
    gatewayGenreCode,
    format,
    youth,
    count: toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10),
    sortCriteria,
  });

  useEffect(() => {
    setSearchQuery({
      queryText: queryText || "",
      queryType,
      page,
      gatewayGenreCode,
      format,
      youth,
      count: toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10),
      sortCriteria,
    });
  }, [queryText, queryType, gatewayGenreCode, page, format, youth, sortCriteria]);

  log("SEARCH QUERY", searchQuery)
  log("SEARCH QUERY TEXT", queryText)

  const alreadySearched = useMemo(() => (
    (
      searchQuery.queryText === queryText
      || searchQuery.queryText === "" && !queryText
    )
    && searchQuery.queryType === queryType
    && searchQuery.gatewayGenreCode === gatewayGenreCode
    && searchQuery.page === page
    && searchQuery.format === format
    && searchQuery.youth === youth
    && searchQuery.sortCriteria === sortCriteria
  ), [
    queryText, queryType, gatewayGenreCode, page, format, youth, sortCriteria,
    searchQuery
  ]);

  log("SEARCH QUERY ALREADY", alreadySearched)

  return (
    <SearchQueryContext.Provider value={{
      searchQuery,
      setSearchQuery,
      alreadySearched,
      alreadySearchedSearchQuery
    }}>
      {children}
    </SearchQueryContext.Provider>
  );
};

export function useSearchQuery() {
  const context = useContext(SearchQueryContext);
  if (context === undefined) {
    throw new Error("useSearchQuery must be used within a SearchQueryProvider");
  }
  return context;
}

export { SearchQueryContext, SearchQueryProvider };
