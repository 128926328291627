import React, { MouseEventHandler } from "react";
import { Book } from "../../types/book";
import "./index.scss";
import BookCoverImage from "../BookCoverImage";

type BookCoverProps = {
  cover: Book["cover"];
  title: Book["title"];
  author: Book["author"];
  modifier?: "bookInfo";
  onClick?: MouseEventHandler<HTMLImageElement>;
  aspectRatio?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export default function BookCoverAspectRatioed({ cover, aspectRatio = "2 / 3", title, author, ...rest }: BookCoverProps) {
  return (
    <div
      className="BookCoverAspectRatioed__Container"
      style={{ aspectRatio }}
      onClick={rest.onClick}
      aria-hidden={true}
    >
      <BookCoverImage
        className="BookCoverAspectRatioed__Image"
        cover={cover}
        title={title}
        author={author}
        {...rest}
      />
    </div>
  );
}
