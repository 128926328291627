import React from "react";
import { Book } from "../../types/book";
import "./index.scss";
import { ucfirst } from "../../utils/string";
import { useTranslation } from "react-i18next";

export default function BookSynopsis({ book }: { book: Book }): JSX.Element {
  const { t } = useTranslation(["common"]);
  return (
    <div className="BookSynopsis__Container">
      <span className="visually-hidden">{ucfirst(t("blurb"))}</span>
      <p className="BookSynopsis__Synopsis">{book?.summary}</p>
    </div>
  );
}