import { useState, useMemo } from "react";
import { RequestStatus } from "../constants/apiStatus";

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

type NormalizedStatusKey = `is${Capitalize<Lowercase<keyof typeof RequestStatus>>}`;
type Statuses = Record<NormalizedStatusKey, boolean>;

const prepareStatuses = (currentStatus: RequestStatus) => (
  Object.values(RequestStatus).reduce((p, status: string) => {
    const normalizedStatus = capitalize(status.toLowerCase());
    const normalizedStatusKey = `is${normalizedStatus}` as NormalizedStatusKey;
    return {
      ...p,
      [normalizedStatusKey]: status === currentStatus
    };
  }, {} as Statuses)
);

const useApiStatus = (currentStatus: RequestStatus = RequestStatus.IDLE) => {
  const [apiStatus, setApiStatus] = useState<RequestStatus>(currentStatus);
  const statuses = useMemo(() => prepareStatuses(apiStatus), [apiStatus]);

  return {
    apiStatus,
    setApiStatus,
    ...statuses,
  };
};

export default useApiStatus;
