import { SortCriteria } from "../types/search";

export enum SortField {
  score="score",
  recent="recent",
  title="title",
  author="author",
  year="year",
  avialabilityDate="availabilityDate",
}

export enum SortDirection {
  asc="asc",
  desc="desc",
}

export function splitSortCriteria(isHome: boolean, sortCriteria?: SortCriteria) {
  const sc = sortCriteria || (isHome && SortCriteria.recent_desc) || SortCriteria.score_desc;
  const [sortField, sortDirection] = sc.split("_") as [SortField, SortDirection];
  return {
    sortField: sortField,
    sortDirection,
  };
}

export function getSortingRequestValueParams(fullSortBy: SortCriteria) {
  const [sortBy, sortDirection] = fullSortBy.split("_");
  if (!sortDirection) {
    return {
      sortBy
    };
  }
  return {
    sortBy,
    sortDirection: sortDirection.toUpperCase(),
  }
}