// NotificationContext.tsx
import { createContext } from 'react';

export enum NotificationNamespace {
  Error = 'Error',
  StatusUpdate = 'StatusUpdate',
  Info = 'Info',
}

export enum NotificationPriority {
  Queue = 'Queue',
  Override = 'Override',
}

export type TimeToSpeakProp = {
  timeToSpeak: number;
}

export type Notification = {
  namespace: NotificationNamespace;
  message: string;
  priority: NotificationPriority;
  id: string;
} & TimeToSpeakProp;

export type IdProp = {
  id: string;
}

export type AddNotificationParam = {
  namespace: NotificationNamespace;
  message: string;
  priority: NotificationPriority;
  id?: string;
}

export type NotificationIdentifier = {
  namespace: NotificationNamespace;
  id?: string;
}

interface NotificationContextType {
  addMessages: (notifactionsToAdd: AddNotificationParam[], notifcationsToRemove?: NotificationIdentifier[]) => void;
  removeMessages: (notifications: NotificationIdentifier[]) => void
}

export const NotificationContext = createContext<NotificationContextType | null>(null);