import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Cancel } from "axios";
import { MutableRefObject } from "react";
import { isNullOrExpired, setJwt } from "../utils/jwt";

const axiosParams = {
  baseURL: import.meta.env.VITE_API_ENDPOINT,
};

const didAbort = (error: any): error is Cancel => axios.isCancel(error);

const axiosInstance = axios.create(axiosParams);

// type AxiosGet<T = any, R = AxiosResponse<T>, D = any> =
//   (url: string, config?: AxiosRequestConfig<D>) => Promise<R>;
type AxiosPost<T = any, R = AxiosResponse<T>, D = any> =
  (url: string, data?: D, config?: AxiosRequestConfig<D>) => Promise<R>;
// type AxiosFn<T = any, R = AxiosResponse<T>, D = any> =
//   AxiosGet<T, R, D> | AxiosPost<T, R, D>;

type AugmentedConfig<C> =
  C & { setAbortController?: (controller: AbortController) => void };

// type AugmentedParameters<F extends AxiosFn> =
//   F extends AxiosGet
//     ? AugmentedParametersGet<F>
//     : F extends AxiosPost
//       ? AugmentedParametersPost<F>
//       : never;

type AugmentedParametersGet<
  T = any,
  F extends (...args: any[]) => Promise<AxiosResponse<T>> = (...args: any[]) => Promise<AxiosResponse<T>>
> =
  [Parameters<F>[0], AugmentedConfig<Parameters<F>[1]>];

type AugmentedParametersPost<
  T = any,
  R = AxiosResponse<T>,
  D = any,
  F extends AxiosPost<T, R, D> = AxiosPost<T, R, D>
> =
  [Parameters<F>[0], Parameters<F>[1], AugmentedConfig<Parameters<F>[2]>];

function isGet<
  T = any,
  F extends (...args: any[]) => Promise<AxiosResponse<T>> = (...args: any[]) => Promise<AxiosResponse<T>>
>(p: any[], f: F): p is AugmentedParametersGet<T> {
  return f.name === "get";
}

function isPost<
  T = any,
  R = AxiosResponse<T>,
  D = any,
  F extends AxiosPost<T, R, D> = AxiosPost<T, R, D>
>(p: any[], f: F): p is AugmentedParametersPost<T, R, D> {
  return f.name === "post";
}

interface AbortedError extends Error {
  aborted: boolean;
}

// TODO merge withAbortControllerPost and withAbortControllerGet
// the only difference is the args[2] in post vs args[1] in get
const withAbortControllerPost = <
  T = any,
  R = AxiosResponse<T>,
  D = any, F extends AxiosPost<T, R, D> = AxiosPost<T, R, D>
>(fn: F) => async (...args: AugmentedParametersPost<T, R, D>) => {
  if (isPost<T, R, D>(args, fn) && args[2]) {
    // Extract abort property from the config
    const { setAbortController } = args[2];

    // Create cancel token and abort method only if abort
    // function was passed
    if (typeof setAbortController === "function") {
      const abortController = new AbortController();
      args[2].signal = abortController.signal;
      setAbortController(abortController);
    }
  }

  try {
    // Pass all arguments from args besides the config
    return await fn(...args);
  } catch (error) {
    // Add "aborted" property to the error if the request was cancelled
    if (didAbort(error)) {
      (error as AbortedError).aborted = true;
    }
    throw error;
  }
};

// TODO merge withAbortControllerPost and withAbortControllerGet
// the only difference is the args[2] in post vs args[1] in get
const withAbortControllerGet = <
  T = any,
  F extends (...args: any[]) => Promise<AxiosResponse<T>> = (...args: any[]) => Promise<AxiosResponse<T>>
>(fn: F) => async (...args: AugmentedParametersGet<T>) => {

  // Conditionally add the JWT token as an Authorization header
  if (args[1] && args[1].withCredentials === true) {
    const jwtToken = localStorage.getItem('jwtToken');
    if (!isNullOrExpired(jwtToken)) {
      args[1].headers = {
        ...(args[1].headers || {}),
        Authorization: `Bearer ${jwtToken}`,
      };
      args[1].withCredentials = undefined;
    }
  }

  if (isGet<T>(args, fn) && args[1]) {
    // Extract abort property from the config
    const { setAbortController } = args[1];

    // Create cancel token and abort method only if abort
    // function was passed
    if (typeof setAbortController === "function") {
      const abortController = new AbortController();
      args[1].signal = abortController.signal;
      setAbortController(abortController);
    }
  }

  try {
    // Pass all arguments from args besides the config
    const response = await fn(...args);

    // Store the Authorization header from the response in local storage
    const authHeader = response.headers['authorization'] || response.headers['Authorization'];
    if (authHeader) {
      const jwtToken = authHeader.split(' ')[1]; // Assumes Bearer scheme
      setJwt(jwtToken);
    }

    return response;
  } catch (error) {
    // Add "aborted" property to the error if the request was cancelled
    if (didAbort(error)) {
      (error as AbortedError).aborted = true;
    }
    throw error;
  }
};

export type AbortControllerRef = MutableRefObject<AbortController | null>;

export const getRefAbortControllerSetter = (abortRef?: AbortControllerRef) => (
  abortRef
  ? {
    setAbortController: (controller: AbortController) => {
      abortRef.current = controller;
    },
  }
  : {}
);

const api = (_axios: AxiosInstance) => ({
  get: <
    T = any,
  >(
    ...args: AugmentedParametersGet<T>
  ) => withAbortControllerGet<T>(
    _axios.get.bind(_axios)
  )(...args),
  post: <
    T = any,
    R = AxiosResponse<T>,
    D = any
  >(
    ...args: AugmentedParametersPost<T, R, D>
  ): Promise<R> => withAbortControllerPost<T, R, D>(
    _axios.post.bind(_axios)
  )(...args),
});

export default api(axiosInstance);
