import { useEffect } from "react";
import { l } from "../utils/log";

const log = l("useFocusOn");

const useFocusOn = (ref: React.RefObject<HTMLElement>, focusAllowed: boolean, setter?: (allowed: boolean) => void) => {
  useEffect(() => {
    log("called");
    if (!ref.current) {
      log("no current ref");
      return;
    }
    if (document.activeElement === ref.current) {
      log("already focused");
      if (focusAllowed) {
        setter && setter(false)
      }
      return ;
    }
    if (focusAllowed) {
      log("focus allowed");
      ref.current.focus();
      setter && setter(false);
    }
  }, [ref, focusAllowed, setter]);
};

export default useFocusOn;
