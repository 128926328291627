import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorContext, ErrorToAction } from "src/contexts/Error/ErrorContext";
import "./index.scss";
import { l } from "../../utils/log";
import Error from "../../components/Error";
import { TabIndex } from "../../types/accessibility";

const log = l("ErrorModal");

function ErrorModal() {
  const { shownErrorStack, dismissFirstFromSource } = useContext(ErrorContext);
  const { t } = useTranslation(["common"]);
  const [dismissedError, setDismissedError] = useState<ErrorToAction | null>(null);
  const [shownError] = shownErrorStack;
  const ref = useRef<HTMLDivElement>(null);

  log("showErrorStack", shownErrorStack);

  useEffect(() => {
    if (dismissedError === null) {
      return;
    }
    if (dismissedError.dismissAction) {
      dismissedError.dismissAction();
    }
    setDismissedError(null);
  }, [dismissedError]);

  const handleDismiss = useCallback(() => {
    setDismissedError(shownError);
    dismissFirstFromSource(shownError.source);
  }, [shownError]);

  useEffect(() => {
    if (!shownError) return;
    log("SHOWN", shownError);
    const id = setTimeout(() => {
      ref.current?.focus();
    }, 300);
    return () => clearTimeout(id);
  }, [shownError]);

  return (shownError && (
    <div className="ErrorModal__Overlay">
      <div ref={ref} className="ErrorModal__Container" aria-label={t("Error message")} tabIndex={TabIndex.reacahblePriorityHighest}>
        <div className="ErrorModal__Header">
          <button className="ErrorModal__CloseButton" onClick={handleDismiss} aria-label={t("Close error message")}>
            ×
          </button>
        </div>
        <Error error={shownError} />
        <button className="ErrorModal__ActionButton" onClick={handleDismiss}>
          {t("Dismiss")}
        </button>
      </div>
    </div>
  )) || null;
}

export default ErrorModal;
