import React, { useState } from "react";
import { DownloadDetailed } from "../../api/implementations/types";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { TabIndex } from "../../types/accessibility";
import { useTranslation } from "react-i18next";

const DownloadItem: React.FC<DownloadDetailed> = ({ title, author, noticeNr, downloadDate }) => {
  const { t } = useTranslation(["common"]);
  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.reachablePriorityLowest);
  const navigate = useNavigate();

  const handleActivation = (event: React.MouseEvent<HTMLDivElement>|React.KeyboardEvent<HTMLDivElement>) => {
    event.currentTarget.blur();
    setTabIndex(TabIndex.notReachableButProgramaticallyFocusable);
    navigate(`/book/${noticeNr}`);
  };

  return (
    <div
      className="DownloadItem clickable"
      role="button"
      tabIndex={tabIndex}
      aria-labelledby={`download-item-title-${noticeNr}`}
      aria-describedby={`download-item-details-${noticeNr}`}
      aria-label={`${t("Go to the book page for")} ${title}`}
      onClick={handleActivation}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === " ") {
          event.preventDefault();
          handleActivation(event);
        }
      }}
    >
      <h3 id={`download-item-title-${noticeNr}`} className="DownloadItem__Title">
        {title}
      </h3>
      <p className="DownloadItem__Author">
        {t("by ")}
        {author}
      </p>
      <p id={`download-item-details-${noticeNr}`} className="DownloadItem__Details">
        {t("Book Number:")} {noticeNr}
        <br />
        {t("Downloaded on ")} {new Date(downloadDate).toLocaleDateString()} {t("at")}{" "}
        {new Date(downloadDate).toLocaleTimeString()}
      </p>
    </div>
  );
};

export default DownloadItem;
