import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Book } from "../../types/book";
import BookInfoField from "./BookInfoField";
import { I18NLabelHandle } from "../../types/search";
import { forcedOrigin } from "../../utils/search";
import { ucfirst } from "../../utils/string";
import "./index.scss";
import CornerFormatPlayer from "../CornerFormatPlayer";
import WishButton from "../WishButton";
import { RemoteServiceId } from "../../api/implementations/types";
import DownloadButton from "../DownloadButton";
import { removeCD, removeLocationComma } from "../../utils/bbr";
import { TabIndex } from "../../types/accessibility";
import BookSynopsis from "../BookSynopsis";
import { LinkableElementId, LinksDisplayRegion } from "../../contexts/QuickLinks/QuickLinksContext";
import { useReplaceQuickLink } from "../../hooks/useReplaceQuickLink";
import BookCoverAspectRatioed from "../BookCoverAspectRatioed";

const isBBROnly = import.meta.env.VITE_RESULTS_ONLY_FROM_ORIGIN && import.meta.env.VITE_ORIGIN === RemoteServiceId.BBR;

const getReaderOnly = (reader?: string) => {
  if (!reader) {
    return undefined;
  }
  const s = reader?.split("Lu par: ");
  return s[s.length - 1];
};

const bookInfoContentId = 'book-info-content';

const containerLink = {
  id: LinkableElementId.bookContent,
  href: `#${bookInfoContentId}`,
  label: 'Go to content',
  priority: 10,
  actionPriority: 1,
};

const replaceInstruction = {
  oldLinkId: LinkableElementId.pageMain,
  newLink: containerLink,
  namespace: LinksDisplayRegion.pageComponent,
}

export default function BookInfo({ book }: { book: Book }): JSX.Element {
  const { t } = useTranslation(["common"]);
  const [expandImageOnMobile, setExpandImageOnMobile] = useState(false);
  const headingRef = useRef<HTMLHeadingElement>(null);

  useReplaceQuickLink(replaceInstruction);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [book]);

  const tags = useMemo(() => book?.motsMatieres?.filter(s => /^[A-Z:.(),-]+$/.test(s)).map(ucfirst).join(', '), [book]);

  return (
    <section
      className="BookInfo__Container"
      id={bookInfoContentId}
    >
      <h2
        className="BookInfo__Title"
        ref={headingRef}
      >
        {book?.title}
      </h2>
      <div
        className="BookInfo__Content"
      >
        <CornerFormatPlayer
          book={book}
          size="full"
          tabIndex={TabIndex.reachablePriorityLowest}
        >
          <WishButton
            size={'full'}
            code={book.code}
            title={book.title}
            wished={book?.wished || false}
            tabIndex={TabIndex.reachablePriorityLowest}
            origin={book.origin}
          />
          <DownloadButton
            size={'full'}
            aria-label={`${t("Download")} "${book.title}"`}
            book={book}
            tabIndex={TabIndex.reachablePriorityLowest}
            aria-hidden="false"
          />
          <div
            aria-hidden="true"
            tabIndex={TabIndex.notReachableButProgramaticallyFocusable}
            className={
              `BookInfo__CoverContainer${
                expandImageOnMobile
                  ? " BookInfo__CoverContainer--expanded"
                  : ""
              }`
            }
            onClick={() => setExpandImageOnMobile(!expandImageOnMobile)}
          >
            <BookCoverAspectRatioed
              modifier="bookInfo"
              cover={book.cover}
              title={book.title}
              author={book.author}
            />
          </div>
        </CornerFormatPlayer>
        <div className="BookInfo__QuickInfoContainer">
          <BookInfoField
            labelI18NHandle={I18NLabelHandle.Author}
            value={book?.author || t("N/A")}
            underlined={typeof book?.author !== "undefined" && book.author !== ""}
          />
          <BookSynopsis book={book} />
          <BookInfoField
            labelI18NHandle={I18NLabelHandle.Reader}
            value={forcedOrigin === RemoteServiceId.ES ? getReaderOnly(book?.reader) : book?.reader}
            underlined
          />
          <BookInfoField
            labelI18NHandle={I18NLabelHandle.Duration}
            value={removeCD(book.media)}
          />
          {((isBBROnly && book?.editor && book?.year && (
            <BookInfoField
              labelI18NHandle={I18NLabelHandle.Editor}
              value={`${removeLocationComma(book.editor)}, ${book.year}`}
            />
          )) || null)}
          <BookInfoField
            labelI18NHandle={I18NLabelHandle.ISBN}
            value={book?.isbn || t("N/A")}
          />
          <BookInfoField
            labelI18NHandle={isBBROnly ? I18NLabelHandle.BookNr : I18NLabelHandle.NoticeNr}
            value={book?.code}
          />
          <BookInfoField
            labelI18NHandle={I18NLabelHandle.Genre}
            value={book?.genre}
            searchValue={book?.gatewayGenreCode}
            underlined
          />
          {(tags && (
            <BookInfoField
              labelI18NHandle={I18NLabelHandle.Tags}
              value={tags.split(',').slice(0, 4)}
              underlined
            />
          )) || null}
          {((!isBBROnly && (
            <BookInfoField
              labelI18NHandle={I18NLabelHandle.PublishedOn}
              value={book?.date}
            />
          )) || null)}
          {(forcedOrigin !== book.origin && (
            <BookInfoField
              labelI18NHandle={I18NLabelHandle.Origin}
              value={book.origin}
              underlined
            />
          )) || null}
          {((!isBBROnly && (
            <BookInfoField
              labelI18NHandle={I18NLabelHandle.Youth}
              value={(book.jeunesse ? t("Yes") : t("No")) || ""}
            />
          )) || null)}
        </div>
      </div>
    </section>
  );
}