import React from "react";
import { useRouteError } from "react-router-dom";
import Footer from "../../components/Footer";
import Page from "../../components/Page";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import NavBar from "../../components/NavBar";
import Error from "../../components/Error";
import { l } from "../../utils/log";

const log = l("ErrorPage");

export default function ErrorPage(): JSX.Element {
  const { t } = useTranslation(["common"]);
  const error: any = useRouteError();

  log(error);

  useDocumentTitle(t("Error Page"));

  return (
    <Page id="error-page"
      header={<NavBar modifier="left-on-mobile" />}
      main={
        <Error error={error} />
      }
      footer={<Footer />}
    />
  );
}
