import { RemoteServiceId } from "../api/implementations/types";

export type SampleFiles = {
  samples: string[];
};

export type BookFiles = SampleFiles
  & BookDownloadFiles;

export type BookDownloadFiles = (
  (BookFilesDaisy & Partial<BookFilesBrf>)
  | (BookFilesBrf & Partial<BookFilesDaisy>)
);

export type ZipKeys = keyof BookFilesDaisy | keyof BookFilesBrf;

type ZipObj = {
  uri: string;
  size: number;
}

type BookFilesDaisy = {
  zip: ZipObj;
}

type BookFilesBrf = {
  zip_brf: ZipObj;
}

export type BookReducedInfo = {
  title: string;
  author: string;
  code: string;
  isbn: string;
  cover: string;
  summary: string;
  mediaType: MediaType;
  origin: RemoteServiceId;
  files: SampleFiles & Partial<BookDownloadFiles>;
  wished?: boolean;
  year?: string;
};

export type Book = BookReducedInfo
& {
  files: BookFiles;
  language: string;
  description: string;
  pages: number;
  publisher: string;
  reader: string;
  date: string;
  genre: string;
  gatewayGenreCode: string;
  getewayGenre: string;
  motsMatieres: string[];
  editor: string;
  media: string;
  jeunesse: boolean;
}

export type BookKeys = keyof Book;

export enum MediaType {
  BIN="BIN",
  CDD="CDD",
}

export interface Books extends Array<Book> {}
