import React, { CSSProperties } from "react";
import "./index.scss";
import { useTranslation } from 'react-i18next';

type ComponentProps = {
  style?: CSSProperties;
  colorScheme?: 'onBlack' | 'onWhite' | 'bWOnBlack' | 'bWOnWhite';
}

const CompanyLogo: React.FC<ComponentProps> = ({ colorScheme }) => {
  const { t } = useTranslation(["common"]);
  return (<a aria-label={t("aria:Go to meow.ch")} key="Meow__LogoLink" href="https://meow.ch" className={`Meow__LogoLink Meow__LogoLink--${colorScheme === undefined ? 'onWhite' : colorScheme}`}>
    &nbsp;
  </a>);
 };

export default CompanyLogo;