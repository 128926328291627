import React, { useCallback, useContext, useEffect, useState } from "react";
import "./index.scss";
import { MdStar, MdStarOutline } from "react-icons/md";
import useResult, { AuthStyle } from "../../hooks/useResult";
import { AddWishParams, addWish, deleteWish } from "../../api/implementations/booksApi";
import { RemoteServiceId } from "../../api/implementations/types";
import { UserContext } from "../../contexts/User/UserContext";
import { TabIndex } from "../../types/accessibility";
import { useTranslation } from "react-i18next";

type RoundedLogoProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  & {
  code: string;
  origin: RemoteServiceId;
  title: string;
  wished: boolean;
  size?: "card" | "full"
  onWishChange?: (prop: { nextIsWished: boolean; code: string; origin: RemoteServiceId; }) => void;
};

const paramsAreSame = (a: AddWishParams|null, b: AddWishParams|null) => {
  return (a === null && b === null)
    || (a !== null && b !== null && a.code === b.code && a.origin === b.origin);
}

export default function WishButton({
  size,
  wished,
  title,
  code,
  origin,
  onWishChange,
  ...rest
}: RoundedLogoProps) {
  const { t } = useTranslation(["common"]);
  const { user } = useContext(UserContext);
  const [isWished, setIsWished] = useState(wished);
  const [lastAction, setLastAction] = useState<"added"|"deleted"|null>(null);
  const { success: wishAdded, request: addRequest, isPending: isLoadingAddWish } = useResult(addWish, "success", paramsAreSame, AuthStyle.NO_AUTH_NULLIFY);
  const { success: wishDeleted, request: deleteRequest, isPending: isLoadingDeleteWish } = useResult(deleteWish, "success", paramsAreSame, AuthStyle.NO_AUTH_NULLIFY);

  useEffect(() => {
    if (isWished || wishAdded === null) return;
    setIsWished(wishAdded);
    setLastAction("added");
  }, [wishAdded]);

  useEffect(() => {
    if (!isWished || wishDeleted === null) return;
    setIsWished(!wishDeleted);
    setLastAction("deleted");
  }, [wishDeleted]);

  useEffect(() => {
    if (!wishAdded && !wishDeleted) return;
    onWishChange && onWishChange({ nextIsWished: isWished, code, origin });
  }, [isWished])

  const toggleWish = useCallback(() => {
    if (isLoadingAddWish || isLoadingDeleteWish) return;
    !isWished
      ? addRequest({ code, origin })
      : deleteRequest({ code, origin });
  }, [isLoadingAddWish, isLoadingDeleteWish, code, origin, isWished]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleWish();
    }
  };

  return user !== null ? (
    <div
      className={`WishButton WishButton--${size || "big"} ActionButton`}
      {...rest}
      onClick={toggleWish}
      onKeyDown={handleKeyDown}
      aria-label={`${isWished ? t("Remove from") : t("Add to")} ${t("your wishlist")}`}
      role="button"
      tabIndex={TabIndex.reachablePriorityLowest}
    >
      <p className="visually-hidden" aria-live={(lastAction !== null) ? "assertive" : "off"}>
        {lastAction === "added"
          ? `${t("Book has been added to")} ${t("your wishlist")}`
          : (lastAction === "deleted"
            ? `${t("Book has been removed from")} ${t("your wishlist")}`
            : "")
        }
      </p>
      {isWished ? <MdStar aria-hidden="true" /> : <MdStarOutline aria-hidden="true" /> }
    </div>
  ) : null;
};
