import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import SearchContext, { SearchContextType } from "./SearchContext";
import { RouteParams, getSearchValuesParam, valuesParamAreDifferent } from "../../utils/search";
import { ValuesParam } from "../../types/search";
import { l } from "../../utils/log";

interface SearchProviderProps {
  children: React.ReactNode;
}

const log = l("SearchProvider");

function SearchProvider({ children }: SearchProviderProps) {
  const routeParams = useParams<RouteParams>();
  const location = useLocation();
  const initialParams = getSearchValuesParam(routeParams, location);
  const [valuesParam, setValuesParam] = useState(initialParams);

  useEffect(() => {
    const newValuesParam = getSearchValuesParam(routeParams, location);
    if (valuesParamAreDifferent(valuesParam, newValuesParam)) {
      setValuesParam(newValuesParam);
    } else {
      log("params are SAME");
    }
  }, [routeParams, location]);

  const setValuesParamSafely = useCallback(<K extends keyof ValuesParam>(
    key: K,
    value: ValuesParam[K] | ((prevValue: ValuesParam[K]) => ValuesParam[K])
  ) => {
    setValuesParam(prev => ({
      ...prev,
      [key]: value instanceof Function ? value(prev[key]) : value
    }));
  }, [setValuesParam]);

  const searchContextValue: SearchContextType = useMemo(() => ({
    ...valuesParam,
    setPage: (page) => setValuesParamSafely('page', page),
    setCount: (count) => setValuesParamSafely('count', count),
    setQueryText: (queryText) => setValuesParamSafely('queryText', queryText),
    setQueryType: (queryType) => setValuesParamSafely('queryType', queryType),
    setGatewayGenreCode: (gatewayGenreCode) => setValuesParamSafely('gatewayGenreCode', gatewayGenreCode),
    setFormat: (format) => setValuesParamSafely('format', format),
    setYouth: (youth) => setValuesParamSafely('youth', youth),
    setSortCriteria: (sortCriteria) => setValuesParamSafely('sortCriteria', sortCriteria),
  }), [valuesParam, setValuesParamSafely]);

  return (
    <SearchContext.Provider value={searchContextValue}>
      {children}
    </SearchContext.Provider>
  );
}

export default SearchProvider;
