export function splitArray<T>(arr: T[], criteria: (item: T) => boolean): [T[], T[]] {
  return arr.reduce<[T[], T[]]>(
    (acc, item) => {
      if (criteria(item)) {
        acc[0].push(item);  // If item matches criteria, put in the first array
      } else {
        acc[1].push(item);  // Otherwise, put in the second array
      }
      return acc;
    },
    [[], []]  // Initial value: two empty arrays
  );
}