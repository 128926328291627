import { TFunction } from "i18next";
import { ValuesParam, Genres, QueryType, Format, YouthText } from "../types/search";

export function createHumanReadableSentence(resultsCount: number, searchQuery: ValuesParam, genres: Genres, t: TFunction): string {
    let parts: string[] = [];

    // Helper functions to get human-readable texts
    function getQueryTypeText(queryType: QueryType): string {
        switch (queryType) {
            case QueryType.author:
                return `${t("writtenBy")} "${searchQuery.queryText}"`;
            case QueryType.text:
                return `${t("containingTheTerms")} "${searchQuery.queryText}"`;
            case QueryType.reader:
                return `${t(`readBy${resultsCount > 1 ? "Plural": ""}`)} "${searchQuery.queryText}"`;
            case QueryType.title:
                return `${t("containingTitle")} "${searchQuery.queryText}"`;
            default:
                return "";
        }
    }

    function getFormatText(format: Format): string {
        switch (format) {
            case Format.daisy:
                return `${t("inFormat")} "DAISY"`;
            case Format.brf:
                return `${t("inFormat")} "BRF"`;
            case Format.all:
                return t("inAllFormats");
            default:
                return "";
        }
    }

    function getYouthText(youth: YouthText): string {
        if (youth === YouthText.yes) {
            return t("forYouth");
        }
        return "";
    }

    parts.push(`${t(!(resultsCount > 1) ? "book" : "books")}`)

    // Handle genre
    if (searchQuery.gatewayGenreCode) {
        const genre = genres.find((genre) => genre.code === searchQuery.gatewayGenreCode);
        if (genre) {
            parts.push(`${t("inGenre")} "${genre.text}"`);
        }
    }

    // Handle queryType and queryText
    if (searchQuery.queryType && searchQuery.queryText) {
        parts.push(getQueryTypeText(searchQuery.queryType));
    }

    // Handle format
    if (searchQuery.format) {
        const formatText = getFormatText(searchQuery.format);
        if (formatText) {
            parts.push(formatText);
        }
    }

    // Handle youth
    if (searchQuery.youth) {
        const youthText = getYouthText(searchQuery.youth);
        if (youthText) {
            parts.push(youthText);
        }
    }

    // Join the parts into a sentence
    const sentence = parts.join(" ");

    return sentence;
}

