import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../components/NavBar";
import Loading from "../../components/Loading";
import BookInfo from "../../components/BookInfo";
import Footer from "../../components/Footer";
import { ErrorSource, TimedError } from "../../contexts/Error/ErrorContext";
import useErrorManager from "../../hooks/useErrorManager";
import errorDsiplayHandleGen from "../../utils/errorHandler";
import Page from "../../components/Page";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import useResult, { AuthStyle } from "../../hooks/useResult";
import { GetBookParams, getBook } from "../../api/implementations/booksApi";
import { RemoteServiceId } from "../../api/implementations/types";
import { useNotifications } from "../../contexts/Notification/NotificationProvider";
import { NotificationPriority } from "../../contexts/Notification/NotificationContext";
import { RequestStatus } from "../../api/constants/apiStatus";
import { l } from "../../utils/log";
import { notification_book_loading, notification_book_error, notification_book_errorAfterSuccessfulRequest, notification_book_loaded } from "../../utils/notifications";

function paramsAreSame(a: GetBookParams | null, b: GetBookParams|null): boolean {
  if (a === null && b === null) return true;
  if (a === null || b === null) return false;
  const { code: bc, origin: bo } = b;
  const { code: ac, origin: ao } = a;
  return ac === bc && ao === bo;
}

const log = l("BookPage");

export default function BookPage(): JSX.Element {
  const { bookCode } = useParams();
  const { book, isPending, apiStatus, attemptRecoveryFromError } = useResult(getBook, "book", paramsAreSame, AuthStyle.NO_AUTH_REFETCH, (bookCode && { code: bookCode, origin: RemoteServiceId.BBR }) || null);
  const { t } = useTranslation(["common"]);
  const { addMessages } = useNotifications();

  useEffect(() => {
    log(" API STATUS ", apiStatus);
    log(" Pending ", isPending);
    log(" BOOK ", book);

    if (isPending || (apiStatus === RequestStatus.IDLE && !book)) {
      log(" adding  ", notification_book_loading.id);
      addMessages([{
        ...notification_book_loading,
        message: t(notification_book_loading.id),
        priority: NotificationPriority.Override
      }], [notification_book_error, notification_book_loaded, notification_book_errorAfterSuccessfulRequest]);
    } else if (apiStatus === RequestStatus.ERROR) {
      log(" adding  ", notification_book_error.id);
      addMessages([{
        ...notification_book_error,
        message: t(notification_book_error.id),
        priority: NotificationPriority.Override
      }], [notification_book_loading, notification_book_loaded, notification_book_errorAfterSuccessfulRequest]);
    } else if (book) {
      log(" adding  ", notification_book_loaded.id);
      addMessages([{
        ...notification_book_loaded,
        message: t(notification_book_loaded.id),
        priority: NotificationPriority.Override
      }], [notification_book_loading, notification_book_errorAfterSuccessfulRequest, notification_book_error]);
    } else {
      log(" adding  ", notification_book_errorAfterSuccessfulRequest.id);
      addMessages([{
        ...notification_book_errorAfterSuccessfulRequest,
        message: t(notification_book_error.id),
        priority: NotificationPriority.Override
      }], [notification_book_loading, notification_book_loaded, notification_book_error]);
    }
  }, [addMessages, isPending, book, apiStatus]);

  useDocumentTitle(`${book?.title || t("Unknown book")}`);

  const makeErrorToAction = useCallback((props: {
    dismiss: () => void,
    timedError: TimedError;
    source: ErrorSource;
  }) => (
    errorDsiplayHandleGen({ attemptRecoveryFromError })(props)
  ), [attemptRecoveryFromError]);

  useErrorManager(ErrorSource.Book, makeErrorToAction);

  return (
    <Page
      header={<NavBar />}
      main={((book && (
        <BookInfo book={book} />
      )) || ((isPending || !book) && (
        <Loading />
      )))}
      footer={<Footer />}
    />
  );
}
