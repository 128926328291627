import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useMatch, Navigate, useNavigate, Route, Routes } from "react-router-dom";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import DownloadItemList from "../../components/DownloadItemList";
import Page from "../../components/Page";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import UserInfo from "../../components/UserInfo";
import "./index.scss";
import WishItemList from "../../components/WishItemList";
import { UserContext } from "../../contexts/User/UserContext";
import AnimatedVerticalLine from "../../components/AnimatedVerticalLine";
import { ucfirst } from "../../utils/string";
import { TabIndex } from "../../types/accessibility";
import { useReplaceQuickLink } from "../../hooks/useReplaceQuickLink";
import { LinkableElementId, LinksDisplayRegion } from "../../contexts/QuickLinks/QuickLinksContext";
import { useAddQuickLink } from "../../hooks/useAddQuickLink";
import useFocusOn from "../../hooks/useFocusOn";

enum TabName {
  wishes = "wishes",
  downloads = "downloads",
}

const mainContentId = 'main-content';
const asideContainerId = 'aside-user-info';

const containerLink = {
  id: LinkableElementId.userAccountPageContent,
  href: `#${mainContentId}`,
  label: 'Go to content',
  priority: 10,
  actionPriority: 1,
};

const replaceInstruction = {
  oldLinkId: LinkableElementId.pageMain,
  newLink: containerLink,
  namespace: LinksDisplayRegion.pageComponent,
}

const userAsideLink = {
  id: LinkableElementId.userAccountAsideContainer,
  href: `#${asideContainerId}`,
  label: 'Go to user info',
  priority: 12,
  actionPriority: 1,
};

const addInstructions = {
  link: userAsideLink,
  namespace: LinksDisplayRegion.pageComponent,
}

export default function UserAccountPage(): JSX.Element {
  const { user } = useContext(UserContext);
  const { t } = useTranslation(["common"]);
  const match = useMatch("/account/*");
  const navigate = useNavigate();
  const tabs = [TabName.wishes, TabName.downloads];
  const [activeIndex, setActiveIndex] = useState(0);
  const tabsRef = useRef<(HTMLButtonElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [focusAllowed, setFocusAllowed] = useState<boolean>(false);

  useEffect(() => {
    const tabName = match?.params["*"] as TabName;
    const index = tabs.indexOf(tabName || TabName.wishes);
    setActiveIndex(index >= 0 ? index : 0);
  }, [match]);

  useDocumentTitle(`${user?.displayName || t("Anonymous")}`);

  const activation = useCallback((index: number, tabName: string) => {
    return () => {
      if (activeIndex === index) {
        return;
      }
      navigate(`/account/${tabName}`);
      setActiveIndex(index);
    }
  }, [navigate, setActiveIndex, activeIndex]);

  useEffect(() => {
    setFocusAllowed(true);
  }, []);

  useReplaceQuickLink(replaceInstruction);
  useAddQuickLink(addInstructions);
  useFocusOn(containerRef, focusAllowed, setFocusAllowed);

  return (
    <Page
      header={<NavBar modifier="centered-logo" />}
      main={
        <div className="UserAccount__SplitContainer">
          <AnimatedVerticalLine />
          <UserInfo id={asideContainerId} />
          <div className="UserAccount__Content">
            <div
              className="Tab__Container"
              role="tablist"
              aria-label={t("User Account Sections Tabs")}
              id={mainContentId}
              ref={containerRef}
              tabIndex={TabIndex.notReachableButProgramaticallyFocusable}
            >
              {tabs.map((tabName, index) => (
                <button
                  ref={(el) => (tabsRef.current[index] = el)}
                  key={tabName}
                  onClick={activation(index, tabName)}
                  onFocus={activation(index, tabName)}
                  className={"clickable Tab__Item" + (activeIndex === index ? " Tab__Item--active" : "")}
                  role="tab"
                  aria-selected={activeIndex === index}
                  aria-controls={`tabpanel-${tabName}`}
                  id={`tab-${tabName}`}
                  tabIndex={activeIndex === index ? TabIndex.reachablePriorityLowest : TabIndex.notReachableButProgramaticallyFocusable}
                >
                  {t(ucfirst(tabName))}
                </button>
              ))}
            </div>
            <div>
              <Routes>
                <Route path="/" element={<Navigate replace to={TabName.wishes} />} />
                <Route
                  path={TabName.wishes}
                  element={
                    <div
                      id={`tabpanel-${TabName.wishes}`}
                      role="tabpanel"
                      aria-labelledby={`tab-${TabName.wishes}`}
                      hidden={activeIndex !== tabs.indexOf(TabName.wishes)}
                    >
                      <WishItemList />
                    </div>
                  }
                />
                <Route
                  path={TabName.downloads}
                  element={
                    <div
                      id={`tabpanel-${TabName.downloads}`}
                      role="tabpanel"
                      aria-labelledby={`tab-${TabName.downloads}`}
                      hidden={activeIndex !== tabs.indexOf(TabName.downloads)}
                    >
                      <DownloadItemList />
                    </div>
                  }
                />
              </Routes>
            </div>
          </div>
        </div>
      }
      footer={<Footer modifier="vertical-line" />}
    />
  );
}
