import { ErrorSource, TimedError, isTimedAxiosError } from "../contexts/Error/ErrorContext";
import { ApiErrorResponse, ErrorName } from "../api/implementations/types";
import { t } from "i18next";
import { AxiosError } from "axios";

const errorDsiplayHandleGen = (
  deps: { attemptRecoveryFromError: (apiError?: ApiErrorResponse["error"]) => void }
) => ({
  dismiss,
  timedError,
  source,
}: {
  dismiss: () => void,
  timedError: TimedError;
  source: ErrorSource;
}) => {
  if (isTimedAxiosError(timedError)) {
    const error = getErrorResponse(timedError.error);
    if (!error) {
      return {
        source,
        message: t("", { ns: "common" }),
        dismissAction: () => {
          dismiss();
          deps.attemptRecoveryFromError();
        },
        timedError,
      };
    }
    switch (error.name) {
      case ErrorName.REQUEST_AHEAD_OF_CACHE:
        return {
          source,
          message: t("Content needs to be refetched", { ns: "common" }),
          dismissAction: () => {
            dismiss();
            deps.attemptRecoveryFromError(error);
          },
          timedError,
        };
      case ErrorName.MALFORMED_REMOTE_RESPONSE:
        const isBadCredentials = error.reason.match(/Invalid login or password/g) !== null;
        return {
          source,
          message: isBadCredentials ? t("Invalid login or password", { ns: "common" }) : error.reason,
          dismissAction: () => {
            dismiss();
            deps.attemptRecoveryFromError(error);
          },
          timedError,
        };
      default:
        return {
          source,
          message: `${error.reason}`,
          dismissAction: () => {
            dismiss();
            deps.attemptRecoveryFromError(error);
          },
          timedError,
        };
    }
  }

  switch (timedError.error.message) {
    case "Network Error":
      return {
        source,
        message: t("Are you sure you are connected to the internet ?", { ns: "common" }),
        dismissAction: () => {
          dismiss();
          deps.attemptRecoveryFromError();
        },
        timedError,
      };
    case "Bad Request":
      return {
        source,
        message: t("The gateway did not manage to provide a proper response to that.", { ns: "common" }),
        dismissAction: () => {
          dismiss();
          deps.attemptRecoveryFromError();
        },
        timedError,
      };
    default:
      return {
        source,
        message: t(`${timedError.error.message}`, { ns: "common" }),
        dismissAction: () => {
          dismiss();
        },
        timedError,
      };
  }
};

export function getErrorResponse(error: AxiosError): ApiErrorResponse["error"] | null {
  if (typeof error.response?.data !== "undefined"
      && typeof (error.response.data as Partial<ApiErrorResponse>)?.error?.name !== "undefined"
    ) {
    const data = error.response.data as ApiErrorResponse;
    return data.error;
  }
  return null;
}

export default errorDsiplayHandleGen;
