export enum TabIndex {
  // tabindex="-1" may be useful for elements that should not be navigated to directly using the Tab key, but need to have keyboard focus set to them. Examples include an off-screen modal window that should be focused when it comes into view, or a form submission error message that should be immediately focused when an errant form is submitted.
  notReachableButProgramaticallyFocusable=-1,
  // tabindex="0" means that the element should be focusable in sequential keyboard navigation, after any positive tabindex values. The focus navigation order of these elements is defined by their order in the document source.
  reachablePriorityLowest=0,
  // A positive value means the element should be focusable in sequential keyboard navigation, with its order defined by the value of the number.
  reacahblePriorityHighest=1,
  reacahblePrioritySecondHighest=2,
  reachPriority3=3,
  reachPriority4=4,
  reachPriority5=5,
}